<template>
  <div>
    <headers />
    <div style="overflow-x: hidden">
      <div class="main-grid">
        <div class="relative overflow-hidden col-start-1 col-span-full" data-projection-id="8">
          <div
            data-testid="tout-video-bg"
            class="col-start-1 col-span-full md:h-[100%] w-[100vw] top-0 left-0 z-[-1] overflow-hidden"
          >
            <div class="relative w-full h-full overflow-hidden min-h-[100%] w-full md:max-w-[1920px] md:mx-auto">
              <img style="width: 100%; height: 100%" :src="require('@/assets/images/yearInReview/b1.png')" alt="" />
            </div>
            <div
              data-testid="tout-color-bg"
              class="absolute top-0 left-0 z-[-1] h-full w-full"
              style="background-color: rgb(255, 255, 255)"
            ></div>
          </div>
          <div
            class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:absolute md:top-0 md:left-0 md:w-full absolute"
          >
            <div
              class="col-end-flex flex flex-col z-1 col-start-2 col-span-15 md:col-start-2 lg:col-start-2 justify-start mt-[30px] md:mt-[40px] mb-[30px] md:my-0"
            >
              <div class="relative flex flex-col justify-between md:h-full my-0">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="flex items-center main-grid--in-grid justify-center">
          <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
            <div class="col-span-6">
              <div
                class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-li:marker:text-iblack-600 prose-a:dark:text-iblack-300 prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 text-left md:text-center"
                style="opacity: 1; transform: none"
              >
                <div>
                  <img
                    style="display: block; margin: auto; width: 200px; height: 112px"
                    :src="require('@/assets/images/yearInReview/b2.png')"
                    alt=""
                  />
                  <h4 style="margin: 0" class="text-2xl md:text-4xl text-2xl md:text-4xl text-iblack-700 text-center">
                    When building something remarkable, the path is rarely linear. 2022 has been a year of renewed focus
                    <br class="hidden md:inline" />on our shared purpose. We continue to be optimistic and believe
                    technology will <br class="hidden md:inline" />increasingly underpin and propel the world towards a
                    better tomorrow. In that spirit, ZEN CAPITAL Growth <br class="hidden md:inline" />companies are helping
                    keep families healthier and safer, creating more accessible financial tools,<br
                      class="hidden md:inline"
                    />
                    protecting critical data for people and companies, bringing joy into people’s lives, and more.
                    <br class="hidden md:inline" />We are grateful to partner with and support visionary leaders
                    redefining what’s possible.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative"><div></div></div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div class="main-grid--in-grid overflow-hidden rounded">
                  <div class="relative" style="padding-top: 69.4511%">
                    <picture class="block transition-opacity duration-500 absolute inset-0 opacity-100"
                      ><source srcset="https://www.datocms-assets.com/65181/1670936246-divider.png" sizes="100vw" />
                      <img
                        class="h-full w-full object-cover object-center"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                        alt=""
                        draggable="false"
                    /></picture>
                  </div>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative"><div></div></div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-image: linear-gradient(rgb(255, 255, 255), rgb(245, 255, 235))"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative"><div></div></div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div class="flex items-center main-grid--in-grid justify-center">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-6">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-textDarkGreen prose-h2:text-textDarkGreen prose-h3:text-textDarkGreen prose-h4:text-textDarkGreen prose-h5:text-textDarkGreen prose-li:marker:text-textDarkGreen prose-a:dark:text-textDarkGreen prose-p:text-textDarkGreen prose-strong:text-textDarkGreen text-left md:text-center"
                        style="opacity: 1; transform: none"
                      >
                        <div>
                          <div
                            style="width: 100vw; position: relative; width: 100vw; margin-left: calc(50% - 50vw)"
                            class="hidden md:grid"
                          >
                            <h1 style="width: 100%; margin: 0">Portfolio Impact</h1>
                            <h4 style="width: 100%; margin: 0">
                              <br class="hidden md:inline" />The visionary leaders of our portfolio companies are
                              turning <br class="hidden md:inline" />extraordinary ideas into world-changing
                              realities.<br class="hidden md:inline" />
                              Here are a few examples:
                            </h4>
                          </div>

                          <div class="md:hidden">
                            <h1 style="margin: 0">Portfolio Impact</h1>
                            <h4 style="margin: 0">
                              <br class="hidden md:inline" />The visionary leaders of our portfolio companies are
                              turning extraordinary ideas into world-changing realities. <br class="hidden md:inline" />
                              Here are a few examples:
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative"><div></div></div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(245, 255, 235)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div class="main-grid--full-grid"><div style="height: 60px"></div></div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(245, 255, 235)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://1password.com/"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-auto md:h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="require('@/assets/images/yearInReview/b3.png')"
                          alt=""
                        />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            1Password
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            1Password helps you protect everything that’s important in your digital life.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://www.airtable.com/home/built-for-you"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-auto md:h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="require('@/assets/images/yearInReview/b4.png')"
                          alt=""
                        />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Airtable
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Airtable is leading a low-code revolution to make the complex world of software more
                            accessible to more people.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(245, 255, 235)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://calendly.com/"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-auto md:h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="require('@/assets/images/yearInReview/b5.png')"
                          alt=""
                        />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Calendly
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Calendly’s scheduling platform simplifies the process of bringing people together.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://drata.com/"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-auto md:h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="require('@/assets/images/yearInReview/b6.png')"
                          alt=""
                        />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Drata
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Drata helps companies automate compliance by monitoring and collecting evidence of their
                            security posture.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(245, 255, 235)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://miro.com/"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-auto md:h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="require('@/assets/images/yearInReview/b7.png')"
                          alt=""
                        />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Miro
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Miro helps teams create and collaborate no matter where they are physically located.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://www.montecarlodata.com/"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-auto md:h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="require('@/assets/images/yearInReview/b8.png')"
                          alt=""
                        />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Monte Carlo
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Monte Carlo ensures data reliability for critical processes for organizations around the
                            world.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(245, 255, 235)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://www.notablehealth.com/"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-auto md:h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="require('@/assets/images/yearInReview/b9.png')"
                          alt=""
                        />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Notable
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Notable makes it possible for clinicians to spend more time with patients by automating
                            administrative work.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://ramp.com/"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-auto md:h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="require('@/assets/images/yearInReview/b10.png')"
                          alt=""
                        />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Ramp
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Ramp helps businesses manage their expenses so they can have more resources to grow.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(245, 255, 235)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div class="main-grid--full-grid"><div style="height: 120px"></div></div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-image: linear-gradient(rgb(245, 255, 235), rgb(255, 233, 233))"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative"><div></div></div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div class="flex items-center main-grid--in-grid justify-center">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-6">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-textDarkPink prose-h2:text-textDarkPink prose-h3:text-textDarkPink prose-h4:text-textDarkPink prose-h5:text-textDarkPink prose-li:marker:text-textDarkPink prose-a:dark:text-textDarkPink prose-p:text-textDarkPink prose-strong:text-textDarkPink text-left md:text-center"
                        style="opacity: 1; transform: none"
                      >
                        <div>
                          <div
                            style="width: 100vw; position: relative; width: 100vw; margin-left: calc(50% - 50vw)"
                            class="hidden md:grid"
                          >
                            <h1 style="width: 100%; margin: 0">In 2022</h1>
                            <h3 style="width: 100%; margin: 0">
                              <br class="hidden md:inline" />We invested $705M of capital in 23 companies including<span
                                style="font-size: 15px; position: relative; bottom: 10px"
                                >[1]</span
                              >:
                            </h3>
                          </div>

                          <div class="md:hidden">
                            <h1>In 2022</h1>
                            <h3 style="margin: 0">
                              <br class="hidden md:inline" />We invested $705M of capital in 23 companies including<span
                                style="font-size: 12px; position: relative; bottom: 8px"
                                >[1]</span
                              >:
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative"><div></div></div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(255, 233, 233)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div class="main-grid--in-grid mt-30">
                  <div class="grid gap-4 grid-cols-1 z-10 relative w-full" style="height: 109px; margin-bottom: 0px">
                    <div
                      class="absolute w-[100vw] max-w-[1300px] left-[-35px] sm:left-[-70px] xl:left-0 top-0 overflow-x-hidden overflow-y-hidden"
                    >
                      <div
                        class="absolute left-0 top-0 translate-x-0 z-10"
                        style="width: 201px; height: 109px; background: "
                      ></div>
                      <div style="transform: none">
                        <div class="flex flex-row">
                          <img
                            :src="require('@/assets/images/yearInReview/b11.png')"
                            width="201"
                            height="109"
                            alt="1Password"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          />
                          <img
                            :src="require('@/assets/images/yearInReview/b12.png')"
                            width="201"
                            height="109"
                            alt="Aurora Solar Inc."
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            :src="require('@/assets/images/yearInReview/b13.png')"
                            width="201"
                            height="109"
                            alt="Axonius, Inc."
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            :src="require('@/assets/images/yearInReview/b14.png')"
                            width="201"
                            height="109"
                            alt="Braze (f/k/a Appboy, Inc.)"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            :src="require('@/assets/images/yearInReview/b15.png')"
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            :src="require('@/assets/images/yearInReview/b16.png')"
                            width="201"
                            height="109"
                            alt="dbt Labs Inc."
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          />
                        </div>
                      </div>
                      <div
                        class="absolute right-0 translate-x-0 top-0 z-10"
                        style="width: 201px; height: 109px; background: "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(255, 233, 233)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div class="main-grid--full-grid"><div style="height: 30px"></div></div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(255, 233, 233)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative"><div></div></div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div class="flex items-center main-grid--in-grid justify-center">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-6">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-textDarkPink prose-h2:text-textDarkPink prose-h3:text-textDarkPink prose-h4:text-textDarkPink prose-h5:text-textDarkPink prose-li:marker:text-textDarkPink prose-a:dark:text-textDarkPink prose-p:text-textDarkPink prose-strong:text-textDarkPink text-left md:text-center"
                        style="opacity: 1; transform: none"
                      >
                        <div>
                          <div
                            style="width: 100vw; position: relative; width: 100vw; margin-left: calc(50% - 50vw)"
                            class="hidden md:grid"
                          >
                            <h1 style="width: 100%; margin: 0">Since our founding in 2013</h1>
                            <h3 style="width: 100%; margin: 0">
                              <br class="hidden md:inline" />We've held steadfast in our commitment to partner with the
                              best<br class="hidden md:inline" />and brightest founders with roots across the globe.
                            </h3>
                          </div>

                          <div class="md:hidden">
                            <h1 style="margin: 0">Since our founding in 2013</h1>
                            <h3 style="margin: 0">
                              <br class="hidden md:inline" />We've held steadfast in our commitment to partner with the
                              best and brightest founders with roots across the globe.
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative"><div></div></div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(255, 233, 233)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid relative">
          <div
            class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
          >
            <div class="pb-[30px] md:pb-0 h-full col-span-7 col-start-2 md:mr-3 md:ml-3">
              <div class="mb-2">
                <div class="main-grid--in-grid overflow-hidden rounded">
                  <div class="relative" style="padding-top: 69.4511%">
                    <picture class="block transition-opacity duration-500 absolute inset-0 opacity-100"
                      ><source
                        srcset="https://www.datocms-assets.com/65181/1670940174-13-6b-capital-invested.png"
                        sizes="100vw" />
                      <img
                        class="h-full w-full object-cover object-center"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                        alt=""
                        draggable="false"
                    /></picture>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden col-start-9 col-span-1 md:flex flex-col items-center">
              <div class="inline-flex justify-center items-center w-[0.5px] h-full inline my-0">
                <div
                  role="separator"
                  aria-orientation="vertical"
                  class="w-full h-full inline h-[175px]"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
            </div>
            <div class="pb-[30px] md:pb-0 h-full col-span-7 col-start-10 md:mr-3 md:ml-3">
              <div
                class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block mt-0 mb-30 md:hidden"
              >
                <div
                  role="separator"
                  aria-orientation="horizontal"
                  class="w-full h-full block"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
              <div class="mb-2">
                <div class="main-grid--in-grid overflow-hidden rounded">
                  <div class="relative" style="padding-top: 69.4511%">
                    <picture class="block transition-opacity duration-500 absolute inset-0 opacity-100"
                      ><source
                        srcset="https://www.datocms-assets.com/65181/1670940043-portfolio-companies.png"
                        sizes="100vw" />
                      <img
                        class="h-full w-full object-cover object-center"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                        alt=""
                        draggable="false"
                    /></picture>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden col-start-17 col-span-1 md:flex flex-col items-center">
              <div class="inline-flex justify-center items-center w-[0.5px] h-full inline my-0">
                <div
                  role="separator"
                  aria-orientation="vertical"
                  class="w-full h-full inline h-[175px]"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
            </div>
            <div class="col-span-7 h-full col-span-7 col-start-18 md:mr-3 md:ml-3">
              <div
                class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block mt-0 mb-30 md:hidden"
              >
                <div
                  role="separator"
                  aria-orientation="horizontal"
                  class="w-full h-full block"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
              <div class="mb-2">
                <div class="main-grid--in-grid overflow-hidden rounded">
                  <div class="relative" style="padding-top: 69.4511%">
                    <picture class="block transition-opacity duration-500 absolute inset-0 opacity-100"
                      ><source
                        srcset="https://www.datocms-assets.com/65181/1670940217-13-countries-invested.png"
                        sizes="100vw" />
                      <img
                        class="h-full w-full object-cover object-center"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                        alt=""
                        draggable="false"
                    /></picture>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
              style="background-color: rgb(255, 233, 233)"
            ></div>
          </div>
        </div>
      </div>
      <div class="main-grid">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div class="main-grid--in-grid mt-30">
                  <div class="grid gap-4 grid-cols-1 z-10 relative w-full" style="height: 109px; margin-bottom: 20px">
                    <div
                      class="absolute w-[100vw] max-w-[1300px] left-[-35px] sm:left-[-70px] xl:left-0 top-0 overflow-x-hidden overflow-y-hidden"
                    >
                      <div
                        class="absolute left-0 top-0 translate-x-0 z-10"
                        style="width: 201px; height: 109px; background: "
                      ></div>
                      <div style="transform: none">
                        <div class="flex flex-row">
                          <img
                            src="https://www.datocms-assets.com/65181/1670940866-password.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940866-password.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940866-password.png?dpr=0.5  300w,
                              https://www.datocms-assets.com/65181/1670940866-password.png?dpr=0.75 450w,
                              https://www.datocms-assets.com/65181/1670940866-password.png          601w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940604-adyen.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940604-adyen.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940604-adyen.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940604-adyen.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940604-adyen.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940607-age-of-learning.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940607-age-of-learning.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940607-age-of-learning.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940607-age-of-learning.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940607-age-of-learning.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940609-airbnb.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940609-airbnb.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940609-airbnb.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940609-airbnb.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940609-airbnb.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940617-airtable.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940617-airtable.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940617-airtable.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940617-airtable.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940617-airtable.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940620-ajaib.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940620-ajaib.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940620-ajaib.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940620-ajaib.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940620-ajaib.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          />
                        </div>
                      </div>
                      <div
                        class="absolute right-0 translate-x-0 top-0 z-10"
                        style="width: 201px; height: 109px; background: "
                      ></div>
                    </div>
                  </div>
                  <div class="grid gap-4 grid-cols-1 z-10 relative w-full" style="height: 109px; margin-bottom: 20px">
                    <div
                      class="absolute w-[100vw] max-w-[1300px] left-[-35px] sm:left-[-70px] xl:left-0 top-0 overflow-x-hidden overflow-y-hidden"
                    >
                      <div
                        class="absolute left-0 top-0 translate-x-0 z-10"
                        style="width: 201px; height: 109px; background: "
                      ></div>
                      <div style="transform: translateX(0px) translateZ(0px)">
                        <div class="flex flex-row">
                          <img
                            src="https://www.datocms-assets.com/65181/1670940623-alibaba.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940623-alibaba.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940623-alibaba.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940623-alibaba.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940623-alibaba.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940626-alteryx.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940626-alteryx.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940626-alteryx.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940626-alteryx.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940626-alteryx.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940629-apttus.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940629-apttus.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940629-apttus.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940629-apttus.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940629-apttus.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940632-articulate.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940632-articulate.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940632-articulate.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940632-articulate.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940632-articulate.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940634-aurora.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940634-aurora.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940634-aurora.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940634-aurora.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940634-aurora.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940638-automattic.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940638-automattic.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940638-automattic.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940638-automattic.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940638-automattic.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          />
                        </div>
                      </div>
                      <div
                        class="absolute right-0 translate-x-0 top-0 z-10"
                        style="width: 201px; height: 109px; background: "
                      ></div>
                    </div>
                  </div>
                  <div class="grid gap-4 grid-cols-1 z-10 relative w-full" style="height: 109px; margin-bottom: 0px">
                    <div
                      class="absolute w-[100vw] max-w-[1300px] left-[-35px] sm:left-[-70px] xl:left-0 top-0 overflow-x-hidden overflow-y-hidden"
                    >
                      <div
                        class="absolute left-0 top-0 translate-x-0 z-10"
                        style="width: 201px; height: 109px; background: "
                      ></div>
                      <div style="transform: none">
                        <div class="flex flex-row">
                          <img
                            src="https://www.datocms-assets.com/65181/1670940641-axonius.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940641-axonius.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940641-axonius.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940641-axonius.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940641-axonius.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940645-bamboohr.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940645-bamboohr.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940645-bamboohr.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940645-bamboohr.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940645-bamboohr.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940650-benchling.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940650-benchling.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940650-benchling.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940650-benchling.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940650-benchling.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940653-betterup.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940653-betterup.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940653-betterup.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940653-betterup.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940653-betterup.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940656-bill.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940656-bill.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940656-bill.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940656-bill.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940656-bill.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          /><img
                            src="https://www.datocms-assets.com/65181/1670940659-blackline.png"
                            srcset="
                              https://www.datocms-assets.com/65181/1670940659-blackline.png?dpr=0.25 150w,
                              https://www.datocms-assets.com/65181/1670940659-blackline.png?dpr=0.5  301w,
                              https://www.datocms-assets.com/65181/1670940659-blackline.png?dpr=0.75 451w,
                              https://www.datocms-assets.com/65181/1670940659-blackline.png          602w
                            "
                            width="201"
                            height="109"
                            class="rounded-[6px] mr-4"
                            style="height: 109px; width: 201px"
                          />
                        </div>
                      </div>
                      <div
                        class="absolute right-0 translate-x-0 top-0 z-10"
                        style="width: 201px; height: 109px; background: "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(255, 233, 233)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      

      <div class="main-grid">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]">
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div class="main-grid--full-grid">
                  <div style="height: 120px;"></div>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]" style="background-image: linear-gradient( rgb(255, 233, 233),rgb(245, 255, 235));"></div></div></div></div>
            </div>
      
      

      <div class="main-grid  mb-30">
        <div class="relative overflow-hidden col-start-1 col-span-full" data-projection-id="5">
          <div
            data-testid="tout-video-bg"
            class="col-start-1 col-span-full md:h-[100%] w-[100vw] top-0 left-0 z-[-1] overflow-hidden"
          >
            <div class="relative w-full h-full overflow-hidden min-h-[100%] w-full md:max-w-[1920px] md:mx-auto">
              <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b17.png')" alt="" />
            </div>
            <div
              data-testid="tout-color-bg"
              class="absolute top-0 left-0 z-[-1] h-full w-full"
              style="background-image: linear-gradient(rgb(255, 233, 233), rgb(198, 198, 253))"
            ></div>
          </div>
          <div
            class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:absolute md:top-0 md:left-0 md:w-full absolute"
          >
            <div
              class="col-end-flex flex flex-col z-1 col-start-2 col-span-15 md:col-start-2 lg:col-start-2 justify-start mt-[30px] md:mt-[40px] mb-[30px] md:my-0"
            >
              <div class="relative flex flex-col justify-between md:h-full my-0">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="relative overflow-hidden col-start-1 col-span-full" data-projection-id="5">
          <div
            data-testid="tout-video-bg"
            class="col-start-1 col-span-full md:h-[100%] w-[100vw] top-0 left-0 z-[-1] overflow-hidden"
          >
            <div class="relative w-full h-full overflow-hidden min-h-[100%] w-full md:max-w-[1920px] md:mx-auto">
              <!-- <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b36.png')" alt="" /> -->
              <img style="display:block;margin:auto;width: 92rem; height: 100%;" :src="require('@/assets/images/yearInReview/b37.png')" alt="" />
            </div>            
          </div>
        </div>
      </div>
      
      
      
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid relative">
          <div
            class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
          >
            <div class="pb-[30px] md:pb-0 h-full col-span-7 col-start-2 md:mr-3 md:ml-3">
              <div class="mb-2">
                <div class="main-grid--in-grid overflow-hidden rounded">
                  <div class="relative">
                    <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b22.png')" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden col-start-9 col-span-1 md:flex flex-col items-center">
              <div class="inline-flex justify-center items-center w-[0.5px] h-full inline my-0">
                <div
                  role="separator"
                  aria-orientation="vertical"
                  class="w-full h-full inline h-[175px]"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
            </div>
            <div class="pb-[30px] md:pb-0 h-full col-span-7 col-start-10 md:mr-3 md:ml-3">
              <div
                class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block mt-0 mb-30 md:hidden"
              >
                <div
                  role="separator"
                  aria-orientation="horizontal"
                  class="w-full h-full block"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
              <div class="mb-2">
                <div class="main-grid--in-grid overflow-hidden rounded">
                  <div class="relative">
                    <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b23.png')" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden col-start-17 col-span-1 md:flex flex-col items-center">
              <div class="inline-flex justify-center items-center w-[0.5px] h-full inline my-0">
                <div
                  role="separator"
                  aria-orientation="vertical"
                  class="w-full h-full inline h-[175px]"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
            </div>
            <div class="col-span-7 h-full col-span-7 col-start-18 md:mr-3 md:ml-3">
              <div
                class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block mt-0 mb-30 md:hidden"
              >
                <div
                  role="separator"
                  aria-orientation="horizontal"
                  class="w-full h-full block"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
              <div class="mb-2">
                <div class="main-grid--in-grid overflow-hidden rounded">
                  <div class="relative">
                    <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b24.png')" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
              style=" , 253)"
            ></div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid relative">
          <div
            class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
          >
            <div class="pb-[30px] md:pb-0 h-full col-span-7 col-start-2 md:mr-3 md:ml-3">
              <div class="mb-2">
                <div class="main-grid--in-grid overflow-hidden rounded">
                  <div class="relative" style="padding-top: 69.4511%">
                    <picture class="block transition-opacity duration-500 absolute inset-0 opacity-100"
                      ><source srcset="https://www.datocms-assets.com/65181/1670944832-77-searches.png" sizes="100vw" />
                      <img
                        class="h-full w-full object-cover object-center"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                        alt=""
                        draggable="false"
                    /></picture>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden col-start-9 col-span-1 md:flex flex-col items-center">
              <div class="inline-flex justify-center items-center w-[0.5px] h-full inline my-0">
                <div
                  role="separator"
                  aria-orientation="vertical"
                  class="w-full h-full inline h-[175px]"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
            </div>
            <div class="pb-[30px] md:pb-0 h-full col-span-7 col-start-10 md:mr-3 md:ml-3">
              <div
                class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block mt-0 mb-30 md:hidden"
              >
                <div
                  role="separator"
                  aria-orientation="horizontal"
                  class="w-full h-full block"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
              <div class="mb-2">
                <div class="main-grid--in-grid overflow-hidden rounded">
                  <div class="relative" style="padding-top: 69.4511%">
                    <picture class="block transition-opacity duration-500 absolute inset-0 opacity-100"
                      ><source
                        srcset="https://www.datocms-assets.com/65181/1670944810-385-high-impact-relationships.png"
                        sizes="100vw" />
                      <img
                        class="h-full w-full object-cover object-center"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                        alt=""
                        draggable="false"
                    /></picture>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden col-start-17 col-span-1 md:flex flex-col items-center">
              <div class="inline-flex justify-center items-center w-[0.5px] h-full inline my-0">
                <div
                  role="separator"
                  aria-orientation="vertical"
                  class="w-full h-full inline h-[175px]"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
            </div>
            <div class="col-span-7 h-full col-span-7 col-start-18 md:mr-3 md:ml-3">
              <div
                class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block mt-0 mb-30 md:hidden"
              >
                <div
                  role="separator"
                  aria-orientation="horizontal"
                  class="w-full h-full block"
                  style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
                ></div>
              </div>
              <div class="mb-2">
                <div class="main-grid--in-grid overflow-hidden rounded">
                  <div class="relative" style="padding-top: 69.4511%">
                    <picture class="block transition-opacity duration-500 absolute inset-0 opacity-100"
                      ><source srcset="https://www.datocms-assets.com/65181/1670944835-40-dinners.png" sizes="100vw" />
                      <img
                        class="h-full w-full object-cover object-center"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                        alt=""
                        draggable="false"
                    /></picture>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
              style=" , 253)"
            ></div>
          </div>
        </div>
      </div>
      
      
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative"><div></div></div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div class="flex items-center main-grid--in-grid justify-center">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-6">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-textDarkBlue prose-h2:text-textDarkBlue prose-h3:text-textDarkBlue prose-h4:text-textDarkBlue prose-h5:text-textDarkBlue prose-li:marker:text-textDarkBlue prose-a:dark:text-textDarkBlue prose-p:text-textDarkBlue prose-strong:text-textDarkBlue md:text-center"
                        style="opacity: 1; transform: none"
                      >
                        <div>
                          <div
                            style="width: 100vw; position: relative; width: 100vw; margin-left: calc(50% - 50vw)"
                            class="hidden md:grid"
                          >
                            <h3 style="width: 100%; margin: 0">
                              We also welcomed Mike Abbott (VP Engineering, Apple Cloud Services)
                              <br class="hidden md:inline" />to our Technical Advisory Board.
                            </h3>
                          </div>
                          <div class="md:hidden">
                            <h3 style="margin: 0">
                              We also welcomed Mike Abbott (VP Engineering, Apple Cloud Services)
                              <br class="hidden md:inline" />to our Technical Advisory Board.
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative"><div></div></div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style=" , 253)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      
      
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative"><div></div></div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div class="flex items-center main-grid--in-grid justify-center">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-6">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-textDarkBlue prose-h2:text-textDarkBlue prose-h3:text-textDarkBlue prose-h4:text-textDarkBlue prose-h5:text-textDarkBlue prose-li:marker:text-textDarkBlue prose-a:dark:text-textDarkBlue prose-p:text-textDarkBlue prose-strong:text-textDarkBlue text-left md:text-center"
                        style="opacity: 1; transform: none"
                      >
                        <div>
                          <div
                            style="width: 100vw; position: relative; width: 100vw; margin-left: calc(50% - 50vw)"
                            class="hidden md:grid"
                          >
                            <h1 style="width: 100%; margin: 0">
                              Insights
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative"><div></div></div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style=" , 253)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative"><div></div></div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div class="flex items-center main-grid--in-grid justify-center">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-6">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-textDarkBlue prose-h2:text-textDarkBlue prose-h3:text-textDarkBlue prose-h4:text-textDarkBlue prose-h5:text-textDarkBlue prose-li:marker:text-textDarkBlue prose-a:dark:text-textDarkBlue prose-p:text-textDarkBlue prose-strong:text-textDarkBlue text-left md:text-center"
                        style="opacity: 1; transform: none"
                      >
                        <div>
                          <div
                            style="width: 100vw; position: relative; width: 100vw; margin-left: calc(50% - 50vw)"
                            class="hidden md:grid"
                          >
                            <h3 style="width: 100%; margin: 0">
                              We empower our portfolio companies with impactful, data-driven insights
                              <br class="hidden md:inline" />to inform decision-making across business operations and
                              strategy.
                            </h3>
                          </div>
                          <div class="md:hidden">
                            <h3 style="margin: 0">
                              We empower our portfolio companies with impactful, data-driven insights
                              <br class="hidden md:inline" />to inform decision-making across business operations and
                              strategy.
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative"><div></div></div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style=" , 253)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b25.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Topline Growth &amp; Operational Efficiency
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            We explore how SaaS leaders balance topline growth with operational efficiency as they scale
                            and what it means to be a top-performing SaaS business to provide useful guidance for
                            scaling your business.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b26.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Professional Development in a Distributed World
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            In partnership with our portfolio company, BetterUp, we looked at how leaders and managers
                            can redefine what professional development looks like.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style=" , 253)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b27.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Engineering in a Hybrid World
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            In partnership with the ZEN CAPITAL Growth Technical Advisory Board, we explored how the shift to
                            remote work has impacted engineering organizations.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b28.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Key Considerations for Hiring Your Next CFO
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Do you have the right finance executive in place to lead your organization? We dove into
                            data on dozens of public software companies to develop a point of view on what
                            qualifications are most critical.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style=" , 253)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid"><div class="main-grid--full-grid"><div class="flex flex-col relative"><div class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"><div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative"><div class="main-grid--full-grid"><div style="height: 120px;"></div></div></div><div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]" style="background-image: linear-gradient(rgb(245, 255, 235), rgb(255, 233, 233));"></div></div></div></div></div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative"><div></div></div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div class="flex items-center main-grid--in-grid justify-center">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-6">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-textDarkPink prose-h2:text-textDarkPink prose-h3:text-textDarkPink prose-h4:text-textDarkPink prose-h5:text-textDarkPink prose-li:marker:text-textDarkPink prose-a:dark:text-textDarkPink prose-p:text-textDarkPink prose-strong:text-textDarkPink md:text-center"
                        style="opacity: 1; transform: none"
                      >
                        <h1>News &amp; Events</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative"><div></div></div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(255, 233, 233)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b29.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Expanded to Europe to Further Support Global Founders
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            We opened an office in London to expand our commitment to partnering with exceptional
                            founders across the globe and in June welcomed General Partner Seth Pierrepont in June.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://www.linkedin.com/feed/update/urn:li:activity:6962853469443526656"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b30.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Celebrated 19 Companies on the 2022 Forbes Cloud 100 List
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Thanks to these incredible leaders, ZEN CAPITAL Growth continues to be the most active dedicated
                            growth investor in Cloud 100 companies since 2016. [5]
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(255, 233, 233)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b31.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Building More Efficient Engineering Teams
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Insights from the 2022 ZEN CAPITAL Growth Engineering Report and Summit which brought together
                            leaders from BetterUp, Chime, dbt, Drata, and Monte Carlo, among other market-defining
                            companies.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b32.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Frank Slootman on Leadership and the War Against Mediocrity
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            In an ZEN CAPITAL Ideas: Growth conversation, Frank shared how he keeps his competitive fire
                            stoked, why execution is critical for driving clearer strategy, and how to transform an
                            organization for maximum scale.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://www.youtube.com/watch?v=jNLmzEN2J6Q"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b33.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Choosing the Wave, Pacing It, and Owning It with Bill Magnuson
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            At Slush 2022, Bill shared how his bet on the wide adoption of smartphones and the change it
                            led to in business and society created the market for Braze and how growing at the right
                            speed has been critical to the company’s success.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(255, 233, 233)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://www.youtube.com/watch?v=oRtla9psS_8"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b34.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Unveiling the Data Behind Effective Scaling
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            In their session at SaaStr Annual, Christine Edmonds and Doug Pepper shared insights from
                            our 2022 Topline Growth and Operational Efficiency report on what strength looks like at all
                            stages of company growth.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="javascript:void(0)"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/yearInReview/b35.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Key Lessons from Miro’s Growth Journey to 30 Million Users
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            In conversation with ZEN CAPITAL Growth’s Matt Jacobson at SaaStr Europa, Andrey Khusid shared
                            the importance of a beginner’s mindset, humility, adaptability, and a commitment to
                            customers.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(255, 233, 233)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div class="main-grid--full-grid"><div style="height: 120px"></div></div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-image: linear-gradient(rgb(255, 233, 233), rgb(245, 255, 235))"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative"><div></div></div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div class="flex items-center main-grid--in-grid justify-center">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-6">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-textDarkGreen prose-h2:text-textDarkGreen prose-h3:text-textDarkGreen prose-h4:text-textDarkGreen prose-h5:text-textDarkGreen prose-li:marker:text-textDarkGreen prose-a:dark:text-textDarkGreen prose-p:text-textDarkGreen prose-strong:text-textDarkGreen text-left md:text-center"
                        style="opacity: 1; transform: none"
                      >
                        <div>
                          <div
                            style="width: 100vw; position: relative; width: 100vw; margin-left: calc(50% - 50vw)"
                            class="hidden md:grid"
                          >
                            <h1 style="width: 100%; margin: 0">
                              Happy Holidays from the <br class="hidden md:inline" />ZEN CAPITAL Growth Team!
                            </h1>
                          </div>
                          <div class="md:hidden">
                            <h1 style="width: 100%; margin: 0">
                              Happy Holidays from the <br class="hidden md:inline" />ZEN CAPITAL Growth Team!
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative"><div></div></div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(245, 255, 235)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="relative main-grid--full-grid">
          <div
            class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
            style="background-color: rgb(245, 255, 235)"
          ></div>
          <div class="main-grid--in-grid md:main-grid md:pt-30">
            <div class="main-grid--in-grid">
              <div class="relative">
                <div class="relative">
                  <div class="grid gap-4 grid-cols-2 md:grid-cols-6 z-10 relative">
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source :srcset="require('@/assets/images/yearInReview/b38.png')" />
                            <img
                              class="h-full w-full object-cover object-center"
                              :src="require('@/assets/images/yearInReview/b38.png')"
                              alt="Adam Alfi"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940830-adam-snyder.png" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Adam Snyder"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940988-adil-bhatia.png" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Adil Bhatia"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940985-aditya-agarwal.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Aditya Agarwal"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940877-aman-kabeer.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Aman Kabeer"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940991-amit-agarwal.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Amit Agarwal"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940924-anna-hendry.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Anna Hendry"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940975-aoife-o-leary.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Aoife O'Leary"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940937-arnav-bimbhet.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Arnav Bimbhet"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670941017-austin-liang.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Austin Liang"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940822-awni-tajiki.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Awni Tajiki"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940965-brad-delaplane.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Brad Delaplane"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940912-calvin-yeoh.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Calvin Yeoh"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940893-candace-widdoes.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Candace Widdoes"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940824-caroline-brand.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Caroline Brand"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940921-caroline-xie.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Caroline Xie"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940857-carolyn-wu.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Carolyn Wu"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670941025-chris-hubbell.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Chris Hubbell"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940994-christine-edmonds.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Christine Edmonds"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940869-claire-davis.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Claire Davis"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940982-divesh-makan.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Divesh Makan"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940915-doug-pepper.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Doug Pepper"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940812-emily-aliamus.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Emily Aliamus"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940807-enlin-chua.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Enlin Chua"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940836-eric-vasquez.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Eric Vasquez"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940968-evan-lintz.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Evan Lintz"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940833-greg-brown.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Greg Brown"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940860-greg-stanger.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Greg Stanger"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940898-jen-hart.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Jen Hart"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940862-jordan-cenidoza.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Jordan Cenidoza"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940854-katie-sousa.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Katie Sousa"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940815-kelsey-mcgregor.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Kelsey McGregor"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940998-kevin-foster.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Kevin Foster"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940954-kryzysztof-lysy.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Krzysztof Lysy"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670941022-leland-speth.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Leland Speth"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source
                              srcset="https://www.datocms-assets.com/65181/1670948860-elizabeth-mossessian.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Liz Mossessian"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940865-mariano-payano.png" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Mariano Payano"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source
                              srcset="https://www.datocms-assets.com/65181/1670940827-marie-louis-o-callaghan.png" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Marie-Louise O'Callaghan"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940918-matt-jacobson.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Matt Jacobson"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940905-max-franzblau.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Max Franzblau"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940958-michelle-rourk.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Michelle Rourk"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940931-mike-anders.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Mike Anders"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940842-morgan-dewalt.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Morgan Dewalt"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940881-murali-joshi.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Murali Joshi"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670955479-nikhil-krishnan.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Nikhil Krishnan"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940818-olivia-saalssa.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Olivia Saalsaa"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940948-penny-shan.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Panny Shan"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670949966-richa-mehta.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Richa Mehta"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670941014-rira-raisi.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Rira Raisi"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670941005-ritika-pai.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Ritika Pai"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940943-roy-luo.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Roy Luo"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670941020-ryan-koh.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Ryan Koh"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940889-sam-o-neill.png" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Sam O'Neill"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940884-sarah-stebbins.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Sarah Stebbins"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940945-seth-pierrepoint.png" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Seth Pierrepont"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670941010-sierra-dooley.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Sierra Dooley"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940810-sruthi-ramaswami.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Sruthi Ramaswami"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940896-story-viebranz.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Story Viebranz"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670941008-tengboo-li.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Tengbo Li"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940940-tommy-dwyer.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Tommy Dwyer"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940839-vivian-guo.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Vivian Guo"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940848-will-griffith.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Will Griffith"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940851-xiaowan-chu.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Xiaowan Chu"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940902-yidrienne-lai.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Yidrienne Lai"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940845-yoonkee-sull.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Yoonkee Sull"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670940874-zach-cherian.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Zach Cherian"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div class="relative group" style="opacity: 1; transform: none; transform-origin: 50% 50% 0px">
                      <div
                        class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer absolute z-10 left-0 top-0 w-full h-full"
                      >
                        <div class="w-full h-full bg-white overflow-hidden"></div>
                      </div>
                      <div class="aspect-w-6 aspect-h-3 overflow-hidden rounded cursor-pointer relative z-20">
                        <div class="w-full h-full overflow-hidden" style="opacity: 1; transform: none">
                          <picture class="block transition-opacity duration-500 w-full h-full opacity-100"
                            ><source srcset="https://www.datocms-assets.com/65181/1670941002-zack-osman.jpg" />
                            <img
                              class="h-full w-full object-cover object-center"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              alt="Zack Osman"
                              draggable="false"
                          /></picture>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="w-full relative py-[30px] md:py-[60px]">
            <div class="main-grid--in-grid text-center max-w-[1300px] mx-auto"></div>
            <div class="flex flex-wrap justify-center gap-4 main-grid--in-grid relative w-full max-w-[1300px] mx-auto">
              <div class="h-[150px] w-[140px] md:w-[23%] mb-[40px] md:mb-0" style="opacity: 1; transform: none">
                <a
                  draggable="false"
                  target="_blank"
                  class="flex h-full w-full flex-col items-center justify-between cursor-pointer opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                  href="https://www.linkedin.com/sharing/share-offsite"
                  ><img
                    src="https://www.datocms-assets.com/65181/1669721756-share-linked-in.png"
                    srcset="
                      https://www.datocms-assets.com/65181/1669721756-share-linked-in.png?dpr=0.25 150w,
                      https://www.datocms-assets.com/65181/1669721756-share-linked-in.png?dpr=0.5  301w,
                      https://www.datocms-assets.com/65181/1669721756-share-linked-in.png?dpr=0.75 451w,
                      https://www.datocms-assets.com/65181/1669721756-share-linked-in.png          602w
                    "
                    width="137"
                    height="auto"
                    alt="Linked In logo"
                  /><span class="inline-flex justify-center items-center button-target"
                    ><svg
                      class="overflow-visible"
                      width="34"
                      height="34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        data-testid="bg-circle"
                        cx="17"
                        cy="17"
                        r="17"
                        fill="#FFFFFF"
                        stroke="#FF0000"
                        stroke-width="0"
                      ></circle>
                      <g class="scale-up" clip-path="url(#a)">
                        <path
                          data-testid="stroke-color-path"
                          d="M17.9485 21.5956 22.5441 17l-4.5956-4.5956M22.5441 17H11.5146h11.0295Z"
                          stroke="#4DB96B"
                          stroke-width="1.29983"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path
                            data-testid="fill-bg-path"
                            fill="#FFFFFF"
                            transform="rotate(-45 23.52081528 1.25735931)"
                            d="M0 0h15.598v15.598H0z"
                          ></path>
                        </clipPath>
                      </defs>
                    </svg>
                    <p class="pl-3" style="color: rgb(40, 95, 58)">LinkedIn</p></span
                  ></a
                >
              </div>
              <div class="h-[150px] w-[140px] md:w-[23%] mb-[40px] md:mb-0" style="opacity: 1; transform: none">
                <a
                  draggable="false"
                  target="_blank"
                  class="flex h-full w-full flex-col items-center justify-between cursor-pointer opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                  href="https://twitter.com/intent/tweet"
                  ><img
                    src="https://www.datocms-assets.com/65181/1669721759-share-twitter.png"
                    srcset="
                      https://www.datocms-assets.com/65181/1669721759-share-twitter.png?dpr=0.25 150w,
                      https://www.datocms-assets.com/65181/1669721759-share-twitter.png?dpr=0.5  301w,
                      https://www.datocms-assets.com/65181/1669721759-share-twitter.png?dpr=0.75 451w,
                      https://www.datocms-assets.com/65181/1669721759-share-twitter.png          602w
                    "
                    width="79"
                    height="auto"
                    alt="Twitter logo"
                  /><span class="inline-flex justify-center items-center button-target"
                    ><svg
                      class="overflow-visible"
                      width="34"
                      height="34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        data-testid="bg-circle"
                        cx="17"
                        cy="17"
                        r="17"
                        fill="#FFFFFF"
                        stroke="#FF0000"
                        stroke-width="0"
                      ></circle>
                      <g class="scale-up" clip-path="url(#a)">
                        <path
                          data-testid="stroke-color-path"
                          d="M17.9485 21.5956 22.5441 17l-4.5956-4.5956M22.5441 17H11.5146h11.0295Z"
                          stroke="#4DB96B"
                          stroke-width="1.29983"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path
                            data-testid="fill-bg-path"
                            fill="#FFFFFF"
                            transform="rotate(-45 23.52081528 1.25735931)"
                            d="M0 0h15.598v15.598H0z"
                          ></path>
                        </clipPath>
                      </defs>
                    </svg>
                    <p class="pl-3" style="color: rgb(40, 95, 58)">Twitter</p></span
                  ></a
                >
              </div>
              <div class="h-[150px] w-[140px] md:w-[23%] mb-[40px] md:mb-0" style="opacity: 1; transform: none">
                <a
                  draggable="false"
                  target="_blank"
                  class="flex h-full w-full flex-col items-center justify-between cursor-pointer opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                  href="https://www.facebook.com/sharer/sharer.php"
                  ><img
                    src="https://www.datocms-assets.com/65181/1669721753-share-fb.png"
                    srcset="
                      https://www.datocms-assets.com/65181/1669721753-share-fb.png?dpr=0.25 150w,
                      https://www.datocms-assets.com/65181/1669721753-share-fb.png?dpr=0.5  301w,
                      https://www.datocms-assets.com/65181/1669721753-share-fb.png?dpr=0.75 451w,
                      https://www.datocms-assets.com/65181/1669721753-share-fb.png          602w
                    "
                    width="137"
                    height="auto"
                    alt="Facebook logo"
                  /><span class="inline-flex justify-center items-center button-target"
                    ><svg
                      class="overflow-visible"
                      width="34"
                      height="34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        data-testid="bg-circle"
                        cx="17"
                        cy="17"
                        r="17"
                        fill="#FFFFFF"
                        stroke="#FF0000"
                        stroke-width="0"
                      ></circle>
                      <g class="scale-up" clip-path="url(#a)">
                        <path
                          data-testid="stroke-color-path"
                          d="M17.9485 21.5956 22.5441 17l-4.5956-4.5956M22.5441 17H11.5146h11.0295Z"
                          stroke="#4DB96B"
                          stroke-width="1.29983"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path
                            data-testid="fill-bg-path"
                            fill="#FFFFFF"
                            transform="rotate(-45 23.52081528 1.25735931)"
                            d="M0 0h15.598v15.598H0z"
                          ></path>
                        </clipPath>
                      </defs>
                    </svg>
                    <p class="pl-3" style="color: rgb(40, 95, 58)">Facebook</p></span
                  ></a
                >
              </div>
              <div class="h-[150px] w-[140px] md:w-[23%] mb-[40px] md:mb-0" style="opacity: 1; transform: none">
                <a
                  draggable="false"
                  class="flex h-full w-full flex-col items-center justify-between cursor-pointer opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                  ><img
                    src="https://www.datocms-assets.com/65181/1669720773-share-email.png"
                    srcset="
                      https://www.datocms-assets.com/65181/1669720773-share-email.png?dpr=0.25 150w,
                      https://www.datocms-assets.com/65181/1669720773-share-email.png?dpr=0.5  301w,
                      https://www.datocms-assets.com/65181/1669720773-share-email.png?dpr=0.75 451w,
                      https://www.datocms-assets.com/65181/1669720773-share-email.png          602w
                    "
                    width="137"
                    height="auto"
                    alt="Email icon"
                  /><span class="inline-flex justify-center items-center button-target"
                    ><svg
                      class="overflow-visible"
                      width="34"
                      height="34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        data-testid="bg-circle"
                        cx="17"
                        cy="17"
                        r="17"
                        fill="#FFFFFF"
                        stroke="#FF0000"
                        stroke-width="0"
                      ></circle>
                      <g class="scale-up" clip-path="url(#a)">
                        <path
                          data-testid="stroke-color-path"
                          d="M17.9485 21.5956 22.5441 17l-4.5956-4.5956M22.5441 17H11.5146h11.0295Z"
                          stroke="#4DB96B"
                          stroke-width="1.29983"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path
                            data-testid="fill-bg-path"
                            fill="#FFFFFF"
                            transform="rotate(-45 23.52081528 1.25735931)"
                            d="M0 0h15.598v15.598H0z"
                          ></path>
                        </clipPath>
                      </defs>
                    </svg>
                    <p class="pl-3" style="color: rgb(40, 95, 58)">Email</p></span
                  ></a
                >
              </div>
            </div>
            <div
              class="absolute w-[100vw] left-[-35px] md:left-0 top-0 h-[calc(100%+30px)] z-[-1]"
              style="background-color: rgb(245, 255, 235)"
            ></div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div class="main-grid--full-grid"><div style="height: 120px"></div></div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-image: linear-gradient(rgb(245, 255, 235), rgb(255, 255, 255))"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div class="flex items-center main-grid--in-grid justify-left">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-5">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-li:marker:text-iblack-600 prose-a:dark:text-iblack-300 prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 md:text-left"
                        style="opacity: 1; transform: none"
                      >
                        <p class="text-sm">
                          [1] Invested capital reflects the amount of capital invested by ZEN CAPITAL Growth only and does
                          not include investment gains or losses, leverage (if any), fees and expenses or exited
                          investments.
                        </p>
                        <p class="text-sm">
                          [2] High-impact introductions refer to all of the introductions ZEN CAPITAL Growth facilitated in
                          2022. These introductions include customer leads, strategic partnerships, search firms,
                          potential investors, board member candidates, advisors, and third party vendors.
                        </p>
                        <p class="text-sm">
                          [3] High-impact introductions over time refer to all of the introductions ZEN CAPITAL Growth
                          facilitated since 2013. These introductions include customer leads, strategic partnerships,
                          search firms, potential investors, board member candidates, advisors, and third party vendors.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div class="flex items-center main-grid--in-grid justify-left">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-5">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-li:marker:text-iblack-600 prose-a:dark:text-iblack-300 prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 md:text-left"
                        style="opacity: 1; transform: none"
                      >
                        <p class="text-sm">
                          [4] High-impact relationships refers to the number of new people the ZEN CAPITAL Growth portfolio
                          engagement team added to its network.
                        </p>
                        <p class="text-sm">
                          [5] This includes current or former ZEN CAPITAL Growth portfolio companies that have been included
                          on the 2022 Forbes Cloud 100 list. According to ZEN CAPITAL analysis of Crunchbase and Pitchbook
                          data. The universe of investors considered in this analysis excludes early-stage venture
                          investors and other investors with an active focus beyond growth equity including public
                          equities.
                        </p>
                        <p class="text-sm">
                          [6] Trademarks are the property of their respective owners. None of the companies illustrated
                          have endorsed or recommended the services of ZEN CAPITAL.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"
                style="background-color: rgb(255, 255, 255)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
